import * as React from "react";
import { Link } from 'gatsby';
import { Mail } from 'tabler-icons-react';
import { Container, Card, Text, Button, Center, Title } from '@mantine/core';

{/* load layout styles */}
import Layout from "../components/layout";
import IndexStyles from '../styles/index_style';

const IndexPage = () => {

    const {classes} = IndexStyles();

    return (
        <Layout>
            <Container fluid className={classes.intro}>
                <Center pt="xl" pb="xl" style={{ }}>
                    <Card shadow="sm" p="xl" radius="sm" withBorder>
                        <Text size="lg" align="center">
                            <Title order={5}>Since it was a bit outdated...</Title>
                            <Title order={1}>We're building a new site!</Title><br/>
                            Check back soon, but if you need us in the meantime:
                        </Text>
                        <Button component={Link} to="/contact" leftIcon={<Mail />} variant="gradient" gradient={{ from: 'yellow', to: 'orange' }} fullWidth mt="md" radius="sm">
                            Contact Us!
                        </Button>
                    </Card>
                </Center>
            </Container>
        </Layout>
    )
};

export default IndexPage;
