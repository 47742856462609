import { createStyles, keyframes } from '@mantine/core';
import { PulseTransition } from './global_style';

const IndexStyle = createStyles((theme) => ({
    orbs: {
        transform: "scale(1)",
        animation: `${PulseTransition} 6s infinite`,
    },
    intro: {
    },
    services: {
        backgroundImage: "linear-gradient(to right top,#ff754a,#ee5e50,#da4a54,#c33856,#aa2857)",
        textAlign: "center",
    }
}));

export default IndexStyle;